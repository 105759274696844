@import '../../../../scss/theme-bootstrap';

.benefits-block {
  width: 100%;
  &__image {
    position: relative;
    img {
      width: auto;
    }
  }
  &__text-content {
    position: relative;
    margin-top: 10px;
    width: 95%;
    padding-#{$ldirection}: 10px;
    padding-bottom: 15px;
    line-height: 1;
    @include breakpoint($landscape-up) {
      width: 75%;
    }
    .content-block__line {
      &--headline {
        @include NeueHaasGrotesk-black;
        font-size: 26px;
        line-height: 22px;
        @include breakpoint($landscape-up) {
          font-size: 36px;
          line-height: 1;
        }
      }
      &--content {
        @include NeueHaasGrotesk-regular;
        font-size: 15px;
        line-height: get-line-height(17px, 22px);
        @include breakpoint($landscape-up) {
          font-size: 17px;
        }
      }
    }
    p {
      line-height: 1;
    }
  }
  &__text-overlapping {
    margin-top: -7%;
    @include breakpoint($landscape-up) {
      margin-top: -3%;
    }
  }
  &__text-negative-padding-5 {
    margin-top: -5%;
  }
  &__text-negative-padding-10 {
    margin-top: -10%;
  }
  &__text-negative-padding-15 {
    margin-top: -15%;
  }
  &__text-negative-padding-20 {
    margin-top: -20%;
  }
}
